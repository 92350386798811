<template>
<div>
    <b-row ref="todo">
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i><span class="h5"> Gráficos de aspectos ambientales</span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col>
                            <span class="h5 text-muted">Nivel global de riesgos</span>
                        </b-col>
                        <b-col md="12" class="text-center">
                            <!-- {{this.$route.params.datosAcordionAspectos}} -->
                            <div id="grafico" ref="grafico" style="width: 1000px;height:600px;margin:auto;"></div>

                        </b-col>
                        <b-col md="12" class="mt-3">
                            <template>
                                <div v-for="(aspectos, k) in datosGraficosAspectos.filter(a=>a.prioridad!='')" :key="k">
                                    <hr>
                                    <span class="h5 text-muted mt-3">{{aspectos.nombre}}</span>
                                    <div :id="'grafico-'+k" :ref="'grafico-'+k" style="width: 600px;height:300px;margin:auto;"></div>
                                </div>
                            </template>
                        </b-col>
                        <b-col md="12" class="mt-3">
                            <b-row class="text-center">
                                <b-col md="12">
                                    <b-button v-if="$route.params.id!=null" :to="{name: 'Selección aspectos', params: {id: $route.params.id}}" variant="dark" class=" mr-2">
                                        <i class="fas fa-arrow-left"></i> Volver
                                    </b-button>
                                    <b-button v-if="$route.params.id==null" :to="{name: 'Selección aspectos'}" variant="dark" class=" mr-2">
                                        <i class="fas fa-arrow-left"></i> Volver
                                    </b-button>
                                    <b-button :disabled="disabled" @click="registrarEvaluacion()" variant="success" type="button">
                                        <i class="fas fa-save"></i> Guardar
                                    </b-button>
                                    <b-button :disabled="disabled" @click="registrarEvaluacionGenerarInforme()" class="ml-2" variant="blue" type="button">
                                        <i class="fas fa-file-word"></i> Generar Informe
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-col>

                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
    </b-row>
    <div id="graficoWord" ref="graficoWord" style="width: 900px;height:600px;display:none"></div>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";
import * as echarts from 'echarts';
var myChart;

import Docxtemplater from "docxtemplater";
import {
    saveAs
} from "file-saver";
import ImageModule from "docxtemplater-image-module-free";
import JSZipUtils from "jszip-utils";
import JSZip from "jszip";
import expressions from 'angular-expressions';
import assign from "lodash.assign";

expressions.filters.lower = function (input) {
    // This condition should be used to make sure that if your input is
    // undefined, your output will be undefined as well and will not
    // throw an error
    if (!input) return input;
    return input.toLowerCase();
}

function angularParser(tag) {
    if (tag === '.') {
        return {
            get: function (s) {
                return s;
            }
        };
    }
    const expr = expressions.compile(
        tag.replace(/(’|‘)/g, "'").replace(/(“|”)/g, '"')
    );
    return {
        get: function (scope, context) {
            let obj = {};
            const scopeList = context.scopeList;
            const num = context.num;
            for (let i = 0, len = num + 1; i < len; i++) {
                obj = assign(obj, scopeList[i]);
            }
            return expr(scope, obj);
        }
    };
}

export default {
    components: {

    },
    data() {
        return {
            datosSession: {
                idCliente: ''
            },
            disabled: false,
            datosGraficosAspectos: this.$route.params.datosAcordionAspectos
        }
    },
    methods: {
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        registrarEvaluacion() {
            let me = this;
            let datosAcordionAspectos = me.$route.params.datosAcordionAspectos;
            let datosNuevaEvaluacion = me.$route.params.datosNuevaEvaluacion;

            me.disabled = true;
            const formData = new FormData();
            /---Necesario para ruta de archivo --/
            formData.append("file", datosNuevaEvaluacion.documentacion);
            formData.append("folder", 'sgma/evaluacion-aspectos');

            for (const i in datosAcordionAspectos) {
                for (const j in datosAcordionAspectos[i].listaGestiones) {
                    if (datosAcordionAspectos[i].listaGestiones[j].documentacion != null) {
                        formData.append("file&&" + i + "&&" + j, datosAcordionAspectos[i].listaGestiones[j].documentacion);
                    }

                }
            }
            formData.append("folder2", 'sgma/evaluacion-aspectos/gestiones-documentos');

            formData.append("datosNuevaEvaluacion", JSON.stringify(datosNuevaEvaluacion));
            formData.append("datosEvaluacionDetalle", JSON.stringify(datosAcordionAspectos));
            formData.append("idCliente", me.datosSession.idCliente);

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-evaluacion-aspecto",
                    formData, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje);
                    me.$router.push({
                        name: 'Evaluación 14001',
                    });
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        registrarEvaluacionGenerarInforme() {
            let me = this;
            let datosAcordionAspectos = me.$route.params.datosAcordionAspectos;
            let datosNuevaEvaluacion = me.$route.params.datosNuevaEvaluacion;

            me.disabled = true;
            const formData = new FormData();
            /---Necesario para ruta de archivo --/
            formData.append("file", datosNuevaEvaluacion.documentacion);
            formData.append("folder", 'sgma/evaluacion-aspectos');

            for (const i in datosAcordionAspectos) {
                for (const j in datosAcordionAspectos[i].listaGestiones) {
                    if (datosAcordionAspectos[i].listaGestiones[j].documentacion != null) {
                        formData.append("file&&" + i + "&&" + j, datosAcordionAspectos[i].listaGestiones[j].documentacion);
                    }

                }
            }
            formData.append("folder2", 'sgma/evaluacion-aspectos/gestiones-documentos');

            formData.append("datosNuevaEvaluacion", JSON.stringify(datosNuevaEvaluacion));
            formData.append("datosEvaluacionDetalle", JSON.stringify(datosAcordionAspectos));
            formData.append("idCliente", me.datosSession.idCliente);

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-evaluacion-aspecto",
                    formData, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje);

                    me.generarDocumento(response.data.idEvaluacion)

                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        base64DataURLToArrayBuffer(dataURL) {
            const base64Regex = /^data:image\/(png|jpg|svg|svg\+xml);base64,/;
            if (!base64Regex.test(dataURL)) {
                return false;
            }
            const stringBase64 = dataURL.replace(base64Regex, "");
            let binaryString;
            if (typeof window !== "undefined") {
                binaryString = window.atob(stringBase64);
            } else {
                binaryString = new Buffer(stringBase64, "base64").toString("binary");
            }
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                const ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }
            return bytes.buffer;
        },
        generarDocumento(idEvaluacion) {
            let me = this;
            me.disabled = true;

            /* if (myChart2 != null && myChart2 != "" && myChart2 != undefined) {
                myChart2.dispose();
            } */

            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/generar-informe-evaluacion-aspectos-ambientales-total", {
                        params: {
                            idCliente: me.datosSession.idCliente,
                            idEvaluacion: idEvaluacion
                        },
                    }
                )
                .then(function (response) {
                    JSZipUtils.getBinaryContent(`${CONSTANTES.URL_RUTA_FRONTEND}plantillas/plantilla-evaluacion-aspectos-ambientales.docx`, function (error, content) {
                        if (error) {
                            console.error(error);
                            return;
                        }
                        var opts = {}
                        opts.centered = true;
                        const imageOpts = {
                            getImage(tag) {
                                return me.base64DataURLToArrayBuffer(tag);
                            },
                            getSize() {
                                return [430, 250];
                            },
                        };

                        var imageModule = new ImageModule(imageOpts);

                        var zip = new JSZip(content);
                        var doc = new Docxtemplater()
                            .loadZip(zip)
                            .setOptions({
                                parser: angularParser
                            })
                            .attachModule(imageModule);
                        doc.setData({
                            //image: response.data.datosAsistentes[0].urlFirma,
                            logo: response.data.dataEvaluacionAspecto[0].urlLogo,
                            fecha: response.data.dataEvaluacionAspecto[0].fecha,
                            listaEvaluacionAspectos: response.data.dataEvaluacionAspecto,
                            listaMedidas: response.data.dataMedidas,
                            fechaGestion: response.data.dataMedidas[0].fechaGestion,
                            grafico: myChart.getDataURL()
                        });

                        try {
                            doc.render();
                        } catch (error) {
                            let e = {
                                message: error.message,
                                name: error.name,
                                stack: error.stack,
                                properties: error.properties
                            };
                            console.log(e)
                            throw error;
                        }
                        var out = doc.getZip().generate({
                            type: "blob",
                            mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        });
                        saveAs(out, "evaluacion-de-aspectos.docx");
                        //me.disabled = false;
                    });
                    me.disabled = false;
                    me.$router.push({
                        name: 'Evaluación 14001',
                    });
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        generarGraficos() {
            let me = this;
            let datosAcordionAspectos = me.$route.params.datosAcordionAspectos.filter(a => a.prioridad != '');
            datosAcordionAspectos.sort((a, b) => (a.nombre > b.nombre) ? -1 : ((b.nombre > a.nombre) ? 1 : 0))

            let dataSets = [];
            for (const i in datosAcordionAspectos) {
                dataSets.push({
                    name: datosAcordionAspectos[i].nombre,
                    value: Math.round((datosAcordionAspectos[i].frecuencia +
                        datosAcordionAspectos[i].importancia +
                        datosAcordionAspectos[i].capacidad) / 8 * 100),
                    nivel: datosAcordionAspectos[i].prioridad
                })
            }
            dataSets.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
            myChart = echarts.init(document.getElementById('grafico'));
            var option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    formatter: function (params, ticket, callback) {
                        var res = '';
                        for (const i in params) {
                            res += params[i].name + ': ' + params[i].value + ' %</br>' + 'Nivel: ' + params[i].data.nivel;
                        }
                        callback(ticket, res);
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    min: 0,
                    max: 100,
                },
                yAxis: {
                    type: 'category',
                    data: (function () {
                        var res = [];
                        for (const i in dataSets) {
                            res.push(dataSets[i].name)
                        }
                        return res;
                    })()
                },
                series: [{
                    /* name: 'Riesgo', */
                    type: 'bar',
                    data: dataSets
                }, ]
            };
            myChart.setOption(option);

            let myChart2;
            for (const j in datosAcordionAspectos) {

                let dataSets2 = [];
                dataSets2.push({
                    name: datosAcordionAspectos[j].nombre,
                    value: Math.round((datosAcordionAspectos[j].frecuencia +
                        datosAcordionAspectos[j].importancia +
                        datosAcordionAspectos[j].capacidad) / 8 * 100),
                    nivel: datosAcordionAspectos[j].prioridad
                })

                var charId = document.getElementById('grafico-' + j);

                myChart2 = echarts.init(charId)
                var option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        },
                        formatter: function (params, ticket, callback) {
                            var res = '';
                            for (const i in params) {
                                res += 'Nivel: ' + params[i].data.nivel + ': ' + params[i].value + ' %';
                            }
                            callback(ticket, res);
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'value',
                        min: 0,
                        max: 100,
                    },
                    yAxis: {
                        type: 'category',
                        data: (function () {
                            var res = [];
                            for (const i in dataSets2) {
                                res.push('Nivel: ' + dataSets2[i].nivel)
                            }
                            return res;
                        })()
                    },
                    series: [{
                        /* name: 'Riesgo', */
                        type: 'bar',
                        data: dataSets2,
                        center: ['50%', '50%']
                    }, ]
                };
                myChart2.setOption(option);
            }
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }

    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosSession.idCliente = user.uidClient;
            this.generarGraficos();
        }
        
    }

}
</script>